import React, { useEffect } from "react";
import { motion } from "framer-motion";

const PrivacyPolicy = ({ setCurrentPage }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, y: -50, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        background: "transparent",
      }}
      variants={animationVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >

      <button
        onClick={() => setCurrentPage("home")}
        style={styles.backButton}
        className="btn btn-link text-white d-flex align-items-center position-absolute top-0 left-0 mt-3 ms-3"
      >
        <i className="fas fa-arrow-left mr-2"></i> Back to Home
      </button>

      <div className="container" style={{ zIndex: 10, position: "relative" }}>
        <div className="row">
          <div
            className="col-12 col-md-8 mx-auto text-left mt-5"
            style={styles.content}
          >
            <h1 style={headingStyles.h1}>Privacy Policy</h1>
            <p style={headingStyles.p}>
              Welcome to the Privacy Policy page for Eduardo Cabral's portfolio
              website ("the Site"). Your privacy is highly valued, and this
              policy outlines how personal information is collected, used, and
              protected.
            </p>
            <p>
              <strong>Last Updated:</strong> {getCurrentDate()}
            </p>

            <h2 style={headingStyles.h2}>1. Information We Collect</h2>
            <p style={headingStyles.p}>
              The Site only collects personal information that you voluntarily
              provide through the contact form, such as your name, email
              address, and message content. No additional forms of data
              collection, such as analytics tracking, are implemented.
            </p>

            <h2 style={headingStyles.h2}>2. Information Usage</h2>
            <p style={headingStyles.p}>
              The information collected is used exclusively to respond to your
              inquiries or requests. Your data will not be used for marketing or
              promotional purposes and will never be shared or sold to third
              parties.
            </p>

            <h2 style={headingStyles.h2}>3. Cookies</h2>
            <p style={headingStyles.p}>
              This Site does not use cookies or any similar tracking
              technologies. Only data essential for the proper functioning of
              the website is processed.
            </p>

            <h2 style={headingStyles.h2}>4. Data Retention</h2>
            <p style={headingStyles.p}>
              Information submitted through the contact form is retained only
              for the time necessary to fulfill the purpose of the
              communication. Once the communication is complete, your details
              will be deleted and not used further.
            </p>

            <h2 style={headingStyles.h2}>5. Your Rights</h2>
            <p style={headingStyles.p}>
              You have the right to access, correct, or request the deletion of
              your personal information provided via the contact form. To make
              such requests, please use the contact form available on the Site.
            </p>

            <h2 style={headingStyles.h2}>6. Changes to This Policy</h2>
            <p style={headingStyles.p}>
              This privacy policy may be updated periodically to reflect changes
              in website practices or legal requirements. It is recommended to
              review this page occasionally to stay informed of any updates.
            </p>

            <h2 style={headingStyles.h2}>7. Contact</h2>
            <p style={headingStyles.p}>
              If you have any questions or concerns regarding this privacy
              policy, feel free to reach out through the contact form provided
              on the Site.
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const styles = {
  backButton: {
    background: "rgba(0, 0, 0, 0.7)",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px 15px",
    cursor: "pointer",
    fontSize: "14px",
    zIndex: 15,
    textDecoration: "none",
    transition: "background 0.3s ease, color 0.3s ease",
  },
  content: {
    color: "white",
    padding: "20px",
    maxWidth: "800px",
    textAlign: "left",
  },
};

const headingStyles = {
  h1: {
    fontSize: "3rem",
    color: "#FFD700",
    marginBottom: "20px",
    textTransform: "uppercase",
  },
  h2: {
    fontSize: "1.75rem",
    color: "#FFD700",
    marginBottom: "15px",
    fontWeight: "600",
  },
  p: {
    fontSize: "1rem",
    color: "lightgray",
    lineHeight: "1.6",
    textWrap: "balance",
  },
};

export default PrivacyPolicy;
