import React, { useState } from "react";
import { sendForm } from "../api/contactApi";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const ContactModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    setTimeout(async () => {
      setSubmitting(false);
      setStatus({ type: "success", message: "Message sent successfully!" });
      setFormData({ name: "", email: "", message: "" });
      setTimeout(() => setStatus(null), 3000);

      try {
        await sendForm(
          formData,
          () => {},
          () => {}
        );
      } catch (error) {
        setStatus({
          type: "danger",
          message: "Something went wrong. Please try again.",
        });

        setTimeout(() => setStatus(null), 3000);
      }
    }, 1200);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton style={{ background: "#111111" }}>
        <Modal.Title style={{ color: "white" }}>Let's Connect</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#111111" }}>
        <form
          onSubmit={handleSubmit}
          className="p-4 rounded d-flex align-items-start"
          style={{
            borderRadius: "8px",
            background: "#111111",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: "white",
              whiteSpace: "pre-line",
              textAlign: "center",
              letterSpacing: "1px",
              marginRight: "20px",
            }}
          >
            {"C\nO\nN\nT\nA\nC\nT\n\nM\nE"}
          </div>

          <div style={{ flex: 1, fontWeight: "bold" }}>
            {status && (
              <div>
                <Alert variant={status.type} className="mb-3">
                  {status.message}
                </Alert>
              </div>
            )}
            {["name", "email", "message"].map((field) => (
              <div className="mb-3 text-white" key={field}>
                <label htmlFor={field} className="form-label">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </label>
                {field !== "message" ? (
                  <input
                    type={field === "email" ? "email" : "text"}
                    id={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="form-control"
                    placeholder={`Your ${field}`}
                    required
                  />
                ) : (
                  <textarea
                    id={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Your message"
                    rows="4"
                    required
                  ></textarea>
                )}
              </div>
            ))}
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" className="me-2" />
              ) : (
                <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
              )}
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
