import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const ModalCertificate = ({ show, image, onClose }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      const timer = setTimeout(() => setShowSpinner(true), 1000);
      return () => clearTimeout(timer);
    } else {
      setShowSpinner(false);
    }
  }, [isLoaded]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="d-flex justify-content-center align-items-center">
        {showSpinner && <Spinner animation="border" />}
        <img
          src={image}
          alt="Certificate"
          className={`img-fluid fade-in ${isLoaded ? "fade-in-loaded" : ""}`}
          style={{
            display: isLoaded ? "block" : "none",
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
          onLoad={() => setIsLoaded(true)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCertificate;
