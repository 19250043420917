import React, { useRef, useEffect } from "react";
import DownloadCVButton from "./DownloadCVButton";
import perfil1 from "../assets/perfil1.avif";

const HamburgerMenu = ({ menuOpen, setMenuOpen, setCurrentPage }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMenuOpen]);

  const handleCloseMenu = () => setMenuOpen(false);

  return (
    <>
      <div
        className={`backdrop ${menuOpen ? "active" : ""}`}
        onClick={handleCloseMenu}
      ></div>

      <div ref={menuRef} className={`hamburger-menu ${menuOpen ? "open" : ""}`}>
        <div className="menu-header">
          <img src={perfil1} alt="User" className="user-image" loading="lazy" />
          <div className="user-info">
            <h4>Eduardo Cabral</h4>
            <p>scorpion0846@gmail.com</p>
          </div>
          <div className="close-icon" onClick={handleCloseMenu}>
            &times;
          </div>
        </div>

        <div className="menu-navigation">
          <h5 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>NAVIGATION</h5>
          <ul>
            {[
              "Home",
              "About me",
              "Proyects",
              "Technologies",
              "Credentials",
              "Contact",
            ].map((section) => (
              <li key={section}>
                <a href={`#${section}`} onClick={handleCloseMenu}>
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="social-icons">
          <a
            href="https://github.com/JackShaw32"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <i className="fab fa-github"></i>
          </a>
          <a
            href="https://linkedin.com/in/raul-eduardo-cabral"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>

        <div className="download-buttons">
          <DownloadCVButton language="EN" />
          <DownloadCVButton language="ES" />
        </div>

        <div className="copyright">
          © 2024 Eduardo Cabral. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default HamburgerMenu;
