import React from "react";
import perfil1 from "../assets/perfil1.avif";

const SobreMi = () => {
  return (
    <>
      <section
        id="About me"
        className="py-5 mt-3 d-flex justify-content-center align-items-center"
        style={{
          background: "transparent",
          position: "relative",
          overflow: "hidden",
          minHeight: "75vh",
        }}
      >
        <div className="container position-relative z-1">
          <div className="row justify-content-center">
            <div
              className="col-12 col-md-10 col-lg-8"
              style={{ width: "710px" }}
            >
              <div
                className="card d-flex flex-column flex-lg-row p-4 align-items-center align-items-lg-start"
                style={{
                  textAlign: "center",
                  background: "transparent",
                  gap: "16px",
                  border: "none",
                }}
              >
                <div className="mb-lg-0 text-center text-lg-start">
                  <img
                    loading="lazy"
                    src={perfil1}
                    alt="Profile"
                    className="rounded-circle img-fluid"
                    style={{
                      maxWidth: "200px",
                      height: "200px",
                      objectFit: "cover",
                      marginRight: "15px",
                    }}
                  />
                </div>

                <div className="d-flex flex-column align-items-center align-items-lg-start text-lg-start text-center">
                  <p
                    className="fw-bold mb-3"
                    id="me"
                    style={{
                      fontSize: "1rem",
                      lineHeight: "1.6",
                      letterSpacing: "0.5px",
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    Passionate developer specializing in innovative
                    technological solutions. With expertise in JavaScript,
                    React, and Node.js.
                  </p>

                  <p
                    className="fw-bold"
                    id="me"
                    style={{
                      fontSize: "1rem",
                      lineHeight: "1.6",
                      letterSpacing: "0.5px",
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    Focus on creating responsive designs, developing web
                    applications, and building scalable custom software. Let’s
                    collaborate and bring your next project to life. 🚀
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
        style={{
          background: "radial-gradient(circle at 50% 50%, #000000, #0d0d2b 60%, #000814)",
          opacity: "0.5",
          position: "relative",
        }}
      ></div>

      </section>
    </>
  );
};

export default SobreMi;
