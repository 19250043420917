import React from "react";
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaBootstrap,
  FaAngular,
  FaReact,
  FaNodeJs,
  FaGitAlt,
  FaGithub,
  FaAws,
} from "react-icons/fa";
import { SiExpress, SiMongodb } from "react-icons/si";
import { motion } from "framer-motion";
import "../css/style.css";

const Skills = () => {
  const skills = [
    { name: "HTML", icon: <FaHtml5 />, color: "#e34c26" },
    { name: "CSS", icon: <FaCss3Alt />, color: "#1572b6" },
    { name: "JavaScript", icon: <FaJsSquare />, color: "#f7df1e" },
    { name: "Bootstrap", icon: <FaBootstrap />, color: "#7952b3" },
    { name: "Angular", icon: <FaAngular />, color: "#dd1b16" },
    { name: "React", icon: <FaReact />, color: "#61dafb" },
    { name: "Node.js", icon: <FaNodeJs />, color: "#32CD32" },
    { name: "Express.js", icon: <SiExpress />, color: "#fff" },
    { name: "MongoDB", icon: <SiMongodb />, color: "#32CD32" },
    { name: "Git", icon: <FaGitAlt />, color: "#f05032" },
    { name: "GitHub", icon: <FaGithub />, color: "#ffffff" },
    { name: "In Progress", icon: <FaAws />, color: "#FF9900", isAws: true },
  ];

  return (
    <section
      id="Technologies"
      className="main-background"
      style={{ backgroundColor: "transparent", marginTop: "6rem" }}
    >
      <div className="container text-center text-white">
        <h2
          className="display-2 display-sm-5 display-md-6 display-lg-3"
          style={{
            fontWeight: "700",
            marginBottom: "0.5rem",
            lineHeight: "1.2",
          }}
        >
          Technologies & Frameworks
        </h2>

        <h3
          className="display-sm-4 display-md-5 display-lg-2"
          style={{
            background:
              "linear-gradient(to right, #FF7F50, #FFD700, #32CD32, #1E90FF, #8A2BE2)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            marginBottom: "2rem",
            fontWeight: "bold",
          }}
        >
          Building powerful, scalable software from innovative ideas.
        </h3>

        <div className="row gy-4">
          {skills.map((skill, index) => (
            <motion.div
              key={index}
              className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex flex-column align-items-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div
                style={{
                  fontSize: "3.5rem",
                  color: skill.color,
                  marginBottom: "0.5rem",
                  marginTop: "4rem",
                }}
              >
                {skill.icon}
              </div>
              <p className="mb-0 text-center">
                {skill.isAws ? (
                  <span
                    className="badge bg-warning text-dark"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {skill.name}
                  </span>
                ) : (
                  <span
                    className="badge bg-success text-black"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {skill.name}
                  </span>
                )}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
