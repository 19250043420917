export const sendForm = async (formBody, resetForm, setSubmitting) => {
  try {
    const response = await fetch(
      "https://formsubmit.co/ajax/6e3a9ecf6b0ebdbd7cac139a6ec711f1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formBody),
      }
    );

    const result = await response.json();

    if (response.ok) {
      resetForm();
      setSubmitting(false);
      return { success: true };
    } else {
      setSubmitting(false);
      throw new Error(
        result.message || "Something went wrong. Please try again."
      );
    }
  } catch (error) {
    setSubmitting(false);
    console.error(error);
    throw new Error("Error: Something went wrong. Please try again.");
  }
};
