import React, { useState } from "react";
import { sendForm } from "../api/contactApi";
import correoImage from "../assets/correo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Spinner, Alert } from "react-bootstrap";
import BackgroundParticles from "./BackgroundParticles";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    setTimeout(async () => {
      setSubmitting(false);
      setStatus({ type: "success", message: "Message sent successfully!" });
      setFormData({ name: "", email: "", message: "" });
      setTimeout(() => setStatus(null), 3000);

      try {
        await sendForm(
          formData,
          () => {},
          () => {}
        );
      } catch (error) {
        setStatus({
          type: "danger",
          message: "Something went wrong. Please try again.",
        });

        setTimeout(() => setStatus(null), 3000);
      }
    }, 1200);
  };

  return (
    <section
      id="Contact"
      className="py-5"
      style={{
        background: "transparent",
        position: "relative",
        overflow: "hidden",
        minHeight: "80vh",
      }}
    >
      <BackgroundParticles />
      <div
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className="container h-100 mt-5 py-5">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <div
                style={{
                  position: "relative",
                  width: "fit-content",
                  textAlign: "center",
                  marginBottom: "0.5rem",
                  display: "inline-block",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    zIndex: -1,
                  }}
                ></div>
                <h2
                  style={{
                    fontSize: "5.5rem",
                    fontWeight: "700",
                    color: "whitesmoke",
                  }}
                >
                  Let's Connect
                </h2>
              </div>
            </div>

            <div className="col-lg-6 d-none d-lg-flex justify-content-center order-lg-1">
              <img
                src={correoImage}
                alt="Email Illustration"
                className="img-fluid rounded w-75"
                loading="lazy"
                style={{
                  maxHeight: "400px",
                  objectFit: "contain",
                  filter:
                    "invert(100%) brightness(100%) saturate(100%) hue-rotate(190deg)",
                }}
              />
            </div>

            <div className="col-lg-6 col-md-12 order-lg-2 position-relative">
              <form
                onSubmit={(e) => handleSubmit(e, setStatus)}
                className="p-4 rounded d-flex align-items-start"
                style={{
                  background: "transparent",
                }}
              >
                <div style={{ flex: 1, fontWeight: "bold" }}>
                  {status && (
                    <div>
                      <Alert variant={status.type} className="mt-3">
                        {status.message}
                      </Alert>
                    </div>
                  )}
                  {["name", "email", "message"].map((field) => (
                    <div className="mb-3 text-white" key={field}>
                      <label htmlFor={field} className="form-label">
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </label>
                      {field !== "message" ? (
                        <input
                          type={field === "email" ? "email" : "text"}
                          id={field}
                          value={formData[field]}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={`Your ${field}`}
                          required
                        />
                      ) : (
                        <textarea
                          id={field}
                          value={formData[field]}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Your message"
                          rows="4"
                          required
                        ></textarea>
                      )}
                    </div>
                  ))}
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" className="me-2" />
                    ) : (
                      <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
                    )}
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
